import React from 'react'
import { Link } from 'gatsby'
import YouTube from 'react-youtube'
import { StaticImage } from 'gatsby-plugin-image'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { StaticHero, ImageViewer } from '/src/components/common'

const MillImageOne = () => {
  return <StaticImage 
    src="../../images/osullivans-1.png" 
    alt="Mill image one"
    width={520}
    height={420}
    placeholder="blurred"/>
}

const MillImageTwo = () => {
  return <StaticImage 
    src="../../images/osullivans-2.png" 
    alt="Mill image two"
    width={520}
    height={420}
    placeholder="blurred" />
}

const MillPage = () => {
  return (
    <Layout hero={<StaticHero title="O'Sullivan Mill">
      <StaticImage 
        src="../../images/osullivans-2.png" 
        alt="Hero image"
        width={1420}
        height={250}
        placeholder="blurred"
        layout="fixed"
        transformOptions={{
          cropFocus: 'center'
        }} />
    </StaticHero>} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row">
        <Col xs={10}>
          <div className="mt-4 mb-4">
            <Link className="back-to-link" to="/mills">
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to mills
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs={10}>
          <Row>
            <Col xs={12}>
              <div className="title-container mb-5">
                <h1 className="title">O'Sullivan Beach</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <p>Orrcon Steel's O'Sullivan Beach mill is a major steel manufacturing facility located in the southern suburbs of Adelaide, South Australia. The mill was originally established in 1950 by the Commonwealth Steel Company and was later acquired by the Hills Group in 1997. Orrcon Steel, a subsidiary of the BlueScope Steel group, purchased the mill from the Hills Group in 2004 and has since invested heavily in modernising and upgrading the facility.</p>
              <p>The O'Sullivan Beach mill produces a wide range of high-quality steel products, including structural tube, precision tube, and line pipe for use in a variety of industries such as construction, mining, and agriculture. The facility boasts state-of-the-art equipment, including a modern tube mill, slitting lines, and advanced quality control systems to ensure the consistency and quality of the products produced.</p>
              <p>The O'Sullivan Beach mill is a vital part of the South Australian economy, employing around 170 people directly and contributing to the employment of many more in the region. The facility is also a major supplier of steel products to customers across Australia, as well as exporting to international markets.</p>
              <p>In recent years, Orrcon Steel has undertaken several significant projects at the O'Sullivan Beach mill to improve the efficiency and productivity of the facility. This has included the installation of a new tube mill in 2016, which has increased the mill's production capacity and allowed it to produce a wider range of high-quality steel products. The company has also invested in improving the energy efficiency of the facility, installing energy-efficient lighting, and upgrading the plant's compressed air systems to reduce energy consumption and greenhouse gas emissions.</p>
              <p>Orrcon Steel is committed to maintaining the highest standards of safety and environmental sustainability at the O'Sullivan Beach mill. The company has implemented a range of measures to ensure the safety of its employees and contractors, including comprehensive training programs and regular safety audits. The facility also has a strong focus on environmental sustainability, with initiatives in place to reduce waste, conserve water, and minimize the impact of its operations on the surrounding environment.</p>
              <p>Overall, the O'Sullivan Beach mill is an important asset for Orrcon Steel and for the South Australian economy. Its modern facilities, advanced technology, and commitment to safety and sustainability make it a leading producer of high-quality steel products for both domestic and international markets.</p>
            </Col>
            <Col md={6}>
              <ImageViewer images={[
                <MillImageTwo />
              ]} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-5">
        <Col xs={10}>
          <Row>
            <Col xs={12}>
              <div className="title-container mb-5">
                <h1 className="title">Mill Tour Video</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <ul className="store-address-list">
                <li>
                  <label>Address</label>
                  <span>29 Morrow Rd, O'Sullivan Beach SA 5166</span>
                </li>
                <li>
                  <label>Phone Number</label>
                  <span>1300 677 266</span>
                </li>
              </ul>
            </Col>
            <Col md={6}>
              <YouTube videoId="6Ply4veWAkk" title="Precision Tubular Steel Manufacturing at O'Sullivan Beach Mill" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default MillPage

export const Head = () => <title>O'Sullivan Mill | Orrcon Steel</title>
